import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import ProfileLayout from "../views/Layout/ProfileLayout.vue";
import DashboardLayoutVr from "../views/Layout/DashboardLayoutVr.vue";
import PageLayout from "../views/Layout/PageLayout";
import AuthBasicLayout from "../views/Layout/AuthBasicLayout";
import AuthCoverLayout from "../views/Layout/AuthCoverLayout";
import AuthIllustrationLayout from "../views/Layout/AuthIllustrationLayout";

import { i18n } from '../main'



import multiguard from 'vue-router-multiguard';
import Login from '@/components/Login'
import Register from '@/components/Register'
import PrivacyPolicy from '@/components/PrivacyPolicy'
import GHLCallback from '@/components/GHLCallback'
import RegisterCompany from '@/components/RegisterCompany'
import Profile from '@/components/Profile'
import Pricing from '@/components/Pricing'
import Subscription from '@/components/Admin/Subscription'
import PricingAdmin from '@/components/PricingAdmin'
import ClientSettings from '@/components/Admin/ClientSettings'
import Agreement from '@/components/Admin/Agreement'
import RestartProcess from '@/components/RestartProcess'
import AddRequest from '@/components/AddRequest'
import Thanks from '@/components/Thanks'
import Step1 from '@/components/Step1'
import LoginAdmin from '@/components/Admin/LoginAdmin'
import Domain from '@/components/Admin/Domain'
import ProdigyWorkArea from '@/components/Admin/ProdigyWorkArea'
import Affiliates from '@/components/Admin/Affiliates'
import LoginDashboard from '@/components/Admin/LoginDashboard'
import AdminDashboard from '@/components/Admin/AdminDashboard'
import GrantDashboard from '@/components/Admin/GrantDashboard'
import CreditHistory from '@/components/Admin/CreditHistory'
import ContractTemplates from '@/components/Admin/ContractTemplates'
import CustomLinkClient from '@/components/CustomLinkClient'
import CompanyProfiles from '@/components/Admin/CompanyProfiles'
import SetPassword from '@/components/SetPassword'
import ClientDashboard from '@/components/ClientDashboard'
import ClientProfiles from '@/components/Admin/ClientProfiles'
import PreviewProducts from '@/components/Admin/PreviewProducts'
import ClientProfileDetail from '@/components/Admin/ClientProfileDetail'
import AddCompany from '@/components/Admin/AddCompany'
import AddClient from '@/components/Admin/AddClient'
import AddLetter from '@/components/Admin/AddLetter'
import EditLetter from '@/components/Admin/EditLetter'
import CreditReportWizard from '@/components/Admin/CreditReportWizard'
import CreditReportWizardStep3 from '@/components/Admin/CreditReportWizardStep3'
import SendLetter from '@/components/Admin/SendLetter'
import EditCompany from '@/components/Admin/EditCompany'
import EditClient from '@/components/Admin/EditClient'
import Requests from '@/components/Admin/Requests'
import CertifiedLetters from '@/components/Admin/CertifiedLetters'
import MyRequest from '@/components/MyRequest'
import Mailing from '@/components/Admin/Mailing'
import Setting from '@/components/Admin/Setting'
import CustomLink from '@/components/Admin/CustomLink'
import ProfileAdmin from '@/components/Admin/Profile'
import Utility from '@/components/common/functions.js'
import ImportCreditReport from '@/components/Admin/ImportCreditReport'
import PaymentGateway from '@/components/Admin/PaymentGateway'
import PaymentGatewayCompany from '@/components/Admin/PaymentGatewayCompany'
import Cart from '@/components/Admin/Cart'
import Checkout from '@/components/Admin/Checkout'
import CompanyUsers from '@/components/Admin/CompanyUsers'
import AddCompanyUser from '@/components/Admin/AddCompanyUser'
import editCompanyUser from '@/components/Admin/EditCompanyUser'
import Fax from '@/components/Admin/Fax'
import QueuedFax from '@/components/Admin/QueuedFax'
import CompanySettings from '@/components/Admin/CompanySettings'
import ConversationSettings from '@/components/Admin/ConversationSettings'
import ChangePassword from '@/components/ChangePassword'

import SubscriptionSettings from '@/components/Admin/SubscriptionSettings'

import Home from '@/components/Home'
import ManageSubscription from '@/components/Admin/ManageSubscription'























const isLoggedIn = (to, from, next) => {
  if (localStorage.getItem('token')) {
    console.log('isLoggedIn ok...', localStorage.getItem('name'))
    next()
  }
  else {
    
  }
}

const isAdmin = (to, from, next) => {
  let admin = localStorage.getItem("is_admin")
  let role = localStorage.getItem('role') ? JSON.parse(localStorage.getItem('role')) : {name:'client'};
  
  if (admin == 1 && localStorage.getItem('token')) {
    console.log('isAdmin ok...', admin)
    if(role.name == 'super_admin')
      next()
    else
      next('/admin/dashboard');
  } else {
    next('/admin/login');
    window.location.reload();
  }
}
const isAdminGeneral = (to, from, next) => {
  
  let admin = localStorage.getItem("is_admin")
  let role = localStorage.getItem('role') ? JSON.parse(localStorage.getItem('role')) : {name:'client'};
  
  if (localStorage.getItem('token') && admin == 1) {
    console.log('isAdmin ok...', admin)
    if(role.name == 'super_admin' || role.name == 'company_admin')
      next()
    else
      next('/admin/dashboard');
  } else {
    next('/admin/login');
    window.location.reload();
  }
}
const isCompanyAdminGeneral = (to, from, next) => {
  
  let admin = localStorage.getItem("is_admin")
  let role = localStorage.getItem('role') ? JSON.parse(localStorage.getItem('role')) : {name:'client'};
  
  if (localStorage.getItem('token') && admin == 1) {
    console.log('isAdmin ok...', admin)
    if(role.name == 'super_admin' || role.name == 'company_admin' || role.name == 'company_user')
      next()
    else
      next('/admin/dashboard');
  } else {
    next('/admin/login');
    window.location.reload();
  }
}
const isClient= (to, from, next) => {
  
  let admin = localStorage.getItem("is_admin")
  let role = localStorage.getItem('role') ? JSON.parse(localStorage.getItem('role')) : {name:'client'};
  
  if (localStorage.getItem('token')) {
    if(role.name == 'client')
      next()
    else
      next('/admin/dashboard');
  } else {
    console.log(to.params.id);
    if(to.name == 'RestartProcess'){
      localStorage.setItem('temp_request_id',to.params.id)
    }
    next('/home');
    // window.location.reload();
  }
}
const documentExist = (to, from, next) => {
  
  let admin = localStorage.getItem("document_flag") ? localStorage.getItem("document_flag") : false;
  if (localStorage.getItem('token')) {
    // if(admin)
      // next('/profile');
    // else
      next()
  } else {
    next('/home');
    window.location.reload();
  }
}
// Dashboard pages
const Dashboard = () => import("../views/Dashboard/Dashboard.vue");
const Discover = () => import("../views/Dashboard/Discover.vue");
const Automotive = () => import("../views/Dashboard/Automotive.vue");
const Sales = () => import("../views/Dashboard/Sales.vue");
const SmartHome = () => import("../views/Dashboard/SmartHome.vue");
const VrDefault = () => import("../views/Dashboard/VrDefault.vue");
const VrInfo = () => import("../views/Dashboard/VrInfo.vue");
const Crm = () => import("../views/Dashboard/Crm.vue");
const GettingStarted = () => import("../views/Dashboard/GettingStarted.vue");

// Pages
// const Pricing = () =>
//   import(/* webpackChunkName: "pages" */ "@/views/Pages/Pricing.vue");
const Rtl = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Rtl.vue");
const ProfileOverview = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Profile/ProfileOverview.vue"
  );
const Messages = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Profile/Messages.vue");
const Projects = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Profile/Projects.vue");
const Reports = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Users/Reports.vue");
const NewUser = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Users/NewUser.vue");
const Settings = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Settings.vue");
const Billing = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Billing.vue");
const Invoice = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Invoice.vue");
const Timeline = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Projects/Timeline.vue");
const Charts = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Charts.vue");
const Alerts = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Alerts.vue");
const Notifications = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Notifications.vue");
const SignUpBasic = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Authentication/SignUp/Basic.vue"
  );
const SignUpCover = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Authentication/SignUp/Cover.vue"
  );
const SignUpIllustration = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Authentication/SignUp/Illustration.vue"
  );

// Applications
const Kanban = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Kanban.vue");
const Wizard = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Wizard.vue");
const Datatables = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Datatables.vue");
const Calendar = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Calendar.vue");

// Ecommerce
const NewProduct = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Ecommerce/Products/NewProduct.vue"
  );
const EditProduct = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Ecommerce/Products/EditProduct.vue"
  );
const ProductPage = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Ecommerce/Products/ProductPage.vue"
  );
const OrderList = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Ecommerce/Orders/OrderList.vue"
  );
const OrderDetails = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Ecommerce/Orders/OrderDetails.vue"
  );

Vue.use(VueRouter);

let vrPages = {
  path: "/",
  component: DashboardLayoutVr,
  name: "Vr",
  children: [
    {
      path: "/pages/dashboards/vr/vr-default",
      name: "VrDefault",
      component: VrDefault,
      meta: {
        groupName: "Dashboards",
      },
    },
    {
      path: "/pages/dashboards/vr/vr-info",
      name: "VrInfo",
      component: VrInfo,
      meta: {
        groupName: "Dashboards",
      },
    },
  ],
};

let profilePages = {
  path: "/",
  component: ProfileLayout,
  name: "Profile",
  children: [
    {
      path: "/pages/pages/profile/overview",
      name: "ProfileOverview",
      component: ProfileOverview,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/profile/messages",
      name: "Messages",
      component: Messages,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/profile/projects",
      name: "Project",
      component: Projects,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};

let userPages = {
  path: "/",
  component: DashboardLayout,
  name: "Users",
  children: [
    {
      path: "/pages/pages/users/reports",
      name: "Reports",
      component: Reports,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/users/new-user",
      name: "NewUser",
      component: NewUser,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};

let accountPages = {
  path: "/",
  component: DashboardLayout,
  name: "Account",
  children: [
    {
      path: "/pages/pages/account/settings",
      name: "Settings",
      component: Settings,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/account/billing",
      name: "Billing",
      component: Billing,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/account/invoice",
      name: "Invoice",
      component: Invoice,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};

let projectsPages = {
  path: "/",
  component: DashboardLayout,
  name: "Projects",
  children: [
    {
      path: "/pages/pages/projects/timeline",
      name: "Timeline",
      component: Timeline,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};

let applicationPages = {
  path: "/",
  component: DashboardLayout,
  name: "Application",
  children: [
    {
      path: "/pages/applications/kanban",
      name: "Kanban",
      component: Kanban,
      meta: {
        groupName: "Applications",
      },
    },
    {
      path: "/pages/applications/wizard",
      name: "Wizard",
      component: Wizard,
      meta: {
        groupName: "Applications",
      },
    },
    {
      path: "/pages/applications/datatables",
      name: "Datatables",
      component: Datatables,
      meta: {
        groupName: "Applications",
      },
    },
    {
      path: "/pages/applications/calendar",
      name: "Calendar",
      component: Calendar,
      meta: {
        groupName: "Applications",
      },
    },
  ],
};

// let pricingPage = {
//   path: "/",
//   component: PageLayout,
//   name: "Pricing Page",
//   children: [
//     {
//       path: "/pages/pages/pricing-page",
//       name: "Pricing",
//       component: Pricing,
//     },
//   ],
// };

let authBasicPages = {
  path: "/",
  component: AuthBasicLayout,
  name: "Authentication Basic",
  children: [
    {
      path: "/pages/authentication/signup/basic",
      name: "SignUpBasic",
      component: SignUpBasic,
    },
  ],
};

let authCoverPages = {
  path: "/",
  component: AuthCoverLayout,
  name: "Authentication Cover",
  children: [
    {
      path: "/pages/authentication/signup/cover",
      name: "SignUpCover",
      component: SignUpCover,
    },
  ],
};

let authIllustrationPages = {
  path: "/",
  component: AuthIllustrationLayout,
  name: "Authentication Illustration",
  children: [
    {
      path: "/pages/authentication/signup/illustration",
      name: "SignUpIllustration",
      component: SignUpIllustration,
    },
  ],
};

const routes = [
  {
    path: "/",
    name: "Home",
    components: {
        default: LoginAdmin
      }
  },
  {
    path: "/admin/client-profile-detail/:id",
      name: "ClientProfileDetail",
      // beforeEnter: isCompanyAdminGeneral,
      components: {
          default: ClientProfileDetail
      },
      // beforeEnter: multiguard([isCompanyAdminGeneral])
  },

    
  {
    path: "/add-request",
    name: "AddRequest",
    components: {
        default: AddRequest
    }
  },
  {
    path: "/auth/reset/:token/:email",
    name: "SetPassword",
    components: {
        default: SetPassword
    }
  },
  // { path: "*", component: Login },
  {
      path: "/signup/:url?",
      name: "Register",
      components: {
          default: Register
      }
  },
  {
    path: "/signup-company/:url?",
    name: "RegisterCompany",
    components: {
        default: RegisterCompany
    }
  },
  { path: "*", component: Login },
  {
      path: "/login/:url?",
      name: "Login",
      components: {
          default: Login
      }
  },
  {
    path: "/step1/:id?",
    name: "Step1",
    beforeEnter: isClient,
    components: {
        default: Step1
    }
  },
  {
  path: "/restart-process/:id",
  name: "RestartProcess",
  beforeEnter: isClient,
  components: {
      default: RestartProcess
  }
  },
  {
  path: "/my-request",
  name: "MyRequest",
  beforeEnter: isClient,
  components: {
      default: MyRequest
  }
  },
  {
    path: "/thanks",
    name: "Thanks",
    components: {
        default: Thanks
    }
  },
  {
    path: "/profile",
    name: "Profile",
    beforeEnter: isClient,
    components: {
        default: Profile
    }
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    beforeEnter: isClient,
    components: {
        default: ChangePassword
    }
  },
  
  {
  path: "/dashboard",
  name: "ClientDashboard",
  beforeEnter: isClient,
  components: {
      default: ClientDashboard
  }
  }
  ,
  {
    path: "/admin/login/:url?",
    name: "LoginAdmin",
    components: {
        default: LoginAdmin
    }
  },
  {
    path: "/home",
    name: "Home",
    components: {
        default: Home
    }
  },
  {
    path: "/privacy/:url?",
    name: "PrivacyPolicy",
    components: {
        default: PrivacyPolicy
    }
  },
  {
    path: "/choose-profile",
    name: "LoginDashboard",
    components: {
        default: LoginDashboard
    }
  },
  {
    path: "/admin",
    name: "LoginAdmin",
    components: {
        default: LoginAdmin
    }
  },
  {
    path: "/ghl/callback",
    name: "GHLCallback",
    components: {
        default: GHLCallback
    }
  },
  {
    path: "/admin/preview-products",
    name: "PreviewProducts",
    beforeEnter:isCompanyAdminGeneral,
    components: {
        default: PreviewProducts
    },
    beforeEnter: multiguard([isCompanyAdminGeneral])
  },
  {
    path: "/admin/agreement",
    name: "Agreement",
    beforeEnter:isCompanyAdminGeneral,
    components: {
        default: Agreement
    },
    beforeEnter: multiguard([isCompanyAdminGeneral])
  },
  {
    path: "/admin/subscription-settings",
    name: "SubscriptionSettings",
    beforeEnter:isAdmin,
    components: {
        default: SubscriptionSettings
    },
    beforeEnter: multiguard([isAdmin])
  },
  {
    path: "/admin/dashboard",
      name: "Dashboard",
      beforeEnter: isCompanyAdminGeneral,
      components: {
          default: AdminDashboard
      },
      beforeEnter: multiguard([isCompanyAdminGeneral])
  },
  {
    path: "/admin/grant-dashboard",
      name: "Grant Dashboard",
      beforeEnter: isCompanyAdminGeneral,
      components: {
          default: GrantDashboard
      },
      beforeEnter: multiguard([isCompanyAdminGeneral])
  },
  {
    path: "/admin/payment-gateway",
      name: "Payment Gateway",
      beforeEnter: isAdmin,
      components: {
          default: PaymentGateway
      }
  },
  {
    path: "/admin/payment-gateway-company",
      name: "Payment Gateway",
      beforeEnter: isCompanyAdminGeneral,
      components: {
          default: PaymentGatewayCompany
      }
  },
  {
    path: "/admin/contract-templates",
      name: "ContractTemplates",
      beforeEnter: isCompanyAdminGeneral,
      components: {
          default: ContractTemplates
      }
  },
  {
    path: "/admin/custom-link/:id",
      name: "CustomLink",
      beforeEnter: isCompanyAdminGeneral,
      components: {
          default: CustomLink
      },
      beforeEnter: multiguard([isCompanyAdminGeneral])
  },
  {
    path: "/custom-link/:id",
      name: "CustomLinkClient",
      components: {
          default: CustomLinkClient
      },
  },
  {
    path: "/admin/credit-report/:id",
      name: "Import Credit Report",
      beforeEnter: isCompanyAdminGeneral,
      components: {
          default: ImportCreditReport
      },
      beforeEnter: multiguard([isCompanyAdminGeneral])
  },
  {
    path: "/admin/credit-report-wizard/:id",
      name: "CreditReportWizard",
      beforeEnter: isCompanyAdminGeneral,
      components: {
          default: CreditReportWizard
      },
      beforeEnter: multiguard([isCompanyAdminGeneral])
  },
  {
    path: "/admin/send-letter/:id",
      name: "Send Letter",
      beforeEnter: isCompanyAdminGeneral,
      components: {
          default: SendLetter
      },
      beforeEnter: multiguard([isCompanyAdminGeneral])
  },
  {
    path: "/admin/credit-report-wizard-step-3/:id",
      name: "Credit Report WizardStep 3",
      beforeEnter: isCompanyAdminGeneral,
      components: {
          default: CreditReportWizardStep3
      },
      beforeEnter: multiguard([isCompanyAdminGeneral])
  },




  {
    path: "/credit-report/:id",
      name: "Import Credit Report",
      components: {
          default: ImportCreditReport
      },
  },
  {
    path: "/credit-report-wizard/:id",
      name: "Credit Report Wizard",
      components: {
          default: CreditReportWizard
      },
  },
  {
    path: "/send-letter/:id",
      name: "Send Letter",
      components: {
          default: SendLetter
      },
  },
  {
    path: "/credit-report-wizard-step-3/:id",
      name: "Credit Report Wizard Step 3",
      components: {
          default: CreditReportWizardStep3
      },
  },


  {
    path: "/admin/credit-history",
      name: "Credit History",
      beforeEnter: isAdminGeneral,
      components: {
          default: CreditHistory
      },
      beforeEnter: multiguard([isAdminGeneral])
  },
  {
    path: "/credit-history",
      name: "Credit History",
      beforeEnter: isClient,
      components: {
          default: CreditHistory
      },
  },
  {
    path: "/admin/my-profile",
      name: "Profile",
      beforeEnter: isCompanyAdminGeneral,
      components: {
          default: ProfileAdmin
      },
      beforeEnter: multiguard([isCompanyAdminGeneral])
  },
  {
    path: "/admin/company-settings",
      name: "Company Settings",
      beforeEnter: isCompanyAdminGeneral,
      components: {
          default: CompanySettings
      },
      beforeEnter: multiguard([isCompanyAdminGeneral])
  },
  {
    path: "/admin/manage-subscription",
      name: "Manage Subscription",
      beforeEnter: isCompanyAdminGeneral,
      components: {
          default: ManageSubscription
      },
      beforeEnter: multiguard([isCompanyAdminGeneral])
  },
  {
    path: "/admin/client-profiles",
      name: "Client Profiles",
      beforeEnter: isCompanyAdminGeneral,
      components: {
          default: ClientProfiles
      },
      beforeEnter: multiguard([isCompanyAdminGeneral])
  },
  {
    path: "/admin/client-settings",
      name: "Client Settings",
      beforeEnter: isCompanyAdminGeneral,
      components: {
          default: ClientSettings
      },
      beforeEnter: multiguard([isCompanyAdminGeneral])
  },
  {
    path: "/admin/client-profile-detail/:id",
      name: "Client Profile Detail",
      beforeEnter: isCompanyAdminGeneral,
      components: {
          default: ClientProfileDetail
      },
      beforeEnter: multiguard([isCompanyAdminGeneral])
  },
  {
    path: "/admin/conversation-settings",
      name: "Conversation Settings",
      beforeEnter: isCompanyAdminGeneral,
      components: {
          default: ConversationSettings
      },
      beforeEnter: multiguard([isCompanyAdminGeneral])
  },
  {
    path: "/admin/company-profiles",
      name: "Company Profiles",
      beforeEnter: isAdmin,
      components: {
          default: CompanyProfiles
      },
      beforeEnter: multiguard([isAdmin])
  },
  {
    path: "/admin/add-company",
      name: "Add Company",
      beforeEnter: isAdmin,
      components: {
          default: AddCompany
      },
      beforeEnter: multiguard([isAdmin])
  },
  {
    path: "/admin/add-company-user",
      name: "Add Company User",
      beforeEnter: isAdminGeneral,
      components: {
          default: AddCompanyUser
      },
      beforeEnter: multiguard([isAdminGeneral])
  },
  {
    path: "/admin/add-letter",
      name: "Add Letter",
      beforeEnter: isAdminGeneral,
      components: {
          default: AddLetter
      },
      beforeEnter: multiguard([isAdminGeneral])
  },
  {
    path: "/admin/edit-letter/:id",
      name: "Edit Letter",
      beforeEnter: isAdminGeneral,
      components: {
          default: EditLetter
      },
      beforeEnter: multiguard([isAdminGeneral])
  },
  {
    path: "/admin/settings",
      name: "Settings",
      beforeEnter: isAdmin,
      components: {
          default: Setting
      },
      beforeEnter: multiguard([isAdmin])
  },
  {
    path: "/admin/affiliates",
      name: "Affiliates",
      beforeEnter: isAdmin,
      components: {
          default: Affiliates
      },
      beforeEnter: multiguard([isAdmin])
  },
  {
    path: "/admin/pricing",
      name: "Pricing",
      beforeEnter: isAdminGeneral,
      components: {
          default: PricingAdmin
      },
      beforeEnter: multiguard([isAdminGeneral])
  },
  {
    path: "/admin/subscription",
      name: "Subscription",
      beforeEnter: isAdminGeneral,
      components: {
          default: Subscription
      },
      beforeEnter: multiguard([isAdminGeneral])
  },
  {
    path: "/admin/company-users",
      name: "Company Users",
      beforeEnter: isAdminGeneral,
      components: {
          default: CompanyUsers
      },
      beforeEnter: multiguard([isAdminGeneral])
  },
  {
    path: "/pricing",
      name: "Pricing",
      beforeEnter: isClient,
      components: {
          default: Pricing
      },
  },
  {
    path: "/admin/edit-company/:id",
      name: "Edit Company",
      beforeEnter: isAdmin,
      components: {
          default: EditCompany
      },
      beforeEnter: multiguard([isAdmin])
  },
  {
    path: "/admin/edit-company-user/:id",
      name: "Edit Company User",
      beforeEnter: isAdminGeneral,
      components: {
          default: editCompanyUser
      },
      beforeEnter: multiguard([isAdminGeneral])
  },
  {
    path: "/admin/add-client",
      name: "Add Client",
      beforeEnter: isCompanyAdminGeneral,
      components: {
          default: AddClient
      },
      beforeEnter: multiguard([isCompanyAdminGeneral])
  },
  {
    path: "/admin/domain",
      name: "Custom Domain",
      beforeEnter: isCompanyAdminGeneral,
      components: {
          default: Domain
      },
      beforeEnter: multiguard([isCompanyAdminGeneral])
  },
  {
    path: "/admin/prodigy-letters",
      name: "Prodigy",
      beforeEnter: isCompanyAdminGeneral,
      components: {
          default: ProdigyWorkArea
      },
      beforeEnter: multiguard([isCompanyAdminGeneral])
  },
  {
    path: "/admin/edit-client/:id",
      name: "Edit Client",
      beforeEnter: isCompanyAdminGeneral,
      components: {
          default: EditClient
      },
      beforeEnter: multiguard([isCompanyAdminGeneral])
  },
  {
    path: "/admin/requests",
      name: "Requests",
      beforeEnter: isCompanyAdminGeneral,
      components: {
          default: Requests
      },
      beforeEnter: multiguard([isCompanyAdminGeneral])
  },
  {
    path: "/admin/certified-letters",
      name: "Certified Letters",
      beforeEnter: isCompanyAdminGeneral,
      components: {
          default: CertifiedLetters
      },
      beforeEnter: multiguard([isCompanyAdminGeneral])
  },
  {
    path: "/admin/mailing",
      name: "Mailing",
      beforeEnter: isAdmin,
      components: {
          default: Mailing
      },
      beforeEnter: multiguard([isAdmin])
  },
  {
    path: "/admin/fax",
      name: "Fax",
      beforeEnter: isAdmin,
      components: {
          default: Fax
      },
      beforeEnter: multiguard([isAdmin])
  },
  {
    path: "/admin/queued-fax",
      name: "Queued Fax",
      beforeEnter: isAdmin,
      components: {
          default: QueuedFax
      },
      beforeEnter: multiguard([isAdmin])
  },
  {
    path: '/admin/cart',
    name: 'Cart',
    component: Cart,
    beforeEnter: isAdmin,
    component: function (resolve) {
      require(['@/components/Admin/Cart.vue'], resolve)
    },
    beforeEnter: multiguard([isAdmin])
  },
  {
    path: '/admin/checkout',
    name: 'Checkout',
    component: Checkout,
    beforeEnter: isAdmin,
    component: function (resolve) {
      require(['@/components/Admin/Checkout.vue'], resolve)
    },
    beforeEnter: multiguard([isAdmin])
  },





















































  {
    path: "/",
    name: "Dashboard",
    redirect: "/pages/dashboards/analytics",
    component: DashboardLayout,
    children: [
      {
        path: "pages/dashboards/analytics",
        name: "Analytics",
        component: Dashboard,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "pages/dashboards/discover",
        name: "Discover",
        component: Discover,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/pages/dashboards/smart-home",
        name: "SmartHome",
        component: SmartHome,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/pages/dashboards/crm",
        name: "Crm",
        component: Crm,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/dashboards/automotive",
        name: "Automotive",
        component: Automotive,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/pages/dashboards/sales",
        name: "Sales",
        component: Sales,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/pages/pages/rtl",
        name: "RTL",
        component: Rtl,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/pages/charts",
        name: "Charts",
        component: Charts,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/pages/alerts",
        name: "Alerts",
        component: Alerts,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/pages/notifications",
        name: "Notifications",
        component: Notifications,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "getting-started",
        name: "Getting Started",
        component: GettingStarted,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/ecommerce/products/new-product",
        name: "NewProduct",
        component: NewProduct,
        meta: {
          groupName: "Ecommerce",
        },
      },
      {
        path: "/pages/ecommerce/products/edit-product",
        name: "EditProduct",
        component: EditProduct,
        meta: {
          groupName: "Ecommerce",
        },
      },
      {
        path: "/pages/ecommerce/products/product-page",
        name: "ProductPage",
        component: ProductPage,
        meta: {
          groupName: "Ecommerce",
        },
      },
      {
        path: "/pages/ecommerce/orders/list",
        name: "OrderList",
        component: OrderList,
        meta: {
          groupName: "Ecommerce",
        },
      },
      {
        path: "/pages/ecommerce/orders/details",
        name: "OrderDetails",
        component: OrderDetails,
        meta: {
          groupName: "Ecommerce",
        },
      },
    ],
  },
  // pricingPage,
  profilePages,
  applicationPages,
  userPages,
  accountPages,
  projectsPages,
  vrPages,
  authBasicPages,
  authCoverPages,
  authIllustrationPages,
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
});
router.beforeEach((to, from, next) => {
  i18n.locale = localStorage.getItem('language') || 'en'
  return next()
})
export default router;
