<template>
    <v-app >

    <v-main class="auth-pages">
      <v-row class="min-vh-100" style="height: 100%;">
        <v-col cols="12" md="6" lg="6">
          <v-container class="pb-0 position-relative new-login-left" style=" justify-content: space-around;position: relative;" >
                
                <v-row class=" new-login-left-box" style="" align="center" justify="center">
                        <div style="text-align: center;">
                        <img v-if="!company && loadingFlag" :height="this.$vuetify.breakpoint.xs ? 100 : 250" class="image-logo2" src="@/assets/img/logo.png">
                                  <img v-if="company && loadingFlag &&  company.logo" :height="this.$vuetify.breakpoint.xs ? 150 : 200" class="image-logo2" :src="base_url_img+company.logo">
                                  <p class="mb-0 text-label ">
                                    ADMIN LOGIN
                                  </p>
                          </div>
                        <v-card class="new-login-left-form" outlined style="background-color:transparent;" v-if="!forgot_flag">
                         
                         <div class="card-padding pb-0" style="text-align:center;" >
                                  
                                  <h2 class=" mb-2 text-label " style=" font-size: 25px;font-weight: 600;">Welcome Back!</h2>
                                  
                                </div>
                                <div class="card-padding pb-4">
                              
                                  <v-text-field
                                    label="Email"
                                    color="#401478"
                                    outlined
                                    ref="email"
                                    rounded
                                    v-model="email"
                                    prepend-inner-icon="fa-envelope"
                                    dense
                                    class="font-size-input input-style py-0 input-login-new"
                                  ></v-text-field>

                                  <v-text-field
                                    label="Password"
                                    color="#401478"
                                    prepend-inner-icon="fa-key"
                                    outlined
                                    rounded
                                    ref="password"
                                    v-model="password"
                                    dense
                                    :type="showpassword ? 'text' : 'password'"
                                    :append-icon="showpassword ? 'fa-eye' : 'fa-eye-slash'"
                                    @click:append="showpassword = !showpassword"
                                    class="font-size-input input-style py-0 input-login-new"
                                  ></v-text-field>
                                  <v-btn
                                    elevation="0"
                                    :ripple="false"
                                    height="43"
                                    rounded
                                    class="
                                      font-weight-bold
                                      text-uppercase
                                      btn-primary
                                      
                                      py-2
                                      px-6
                                      me-2
                                      mt-7
                                      mb-2
                                      w-100
                                    "
                                    color="#401478"
                                    small
                                    style="margin-top: -40px;"
                                    v-on:click="checkForm"
                                    >Login</v-btn
                                  >
                                  
                                  <div class="text-center">
                                    <p class="text-sm text-body font-weight-light mt-3 mb-0" style="color:#7b809a !important;">
                                      <a
                                        @click="forgot_flag = true"
                                        class="
                                            text-decoration-none
                                          font-weight-bold
                                        "
                                        >Forgot Password?</a
                                      >
                                    </p>
                                  </div>
                                </div>
                        </v-card>
                        <v-card class="new-login-left-form" outlined style="background-color:transparent;" v-if="forgot_flag">
                         
                         <div class="card-padding pb-0" style="text-align:center;" >
                                  
                                  <h2 class=" mb-2 text-label " style=" font-size: 25px;font-weight: 600;">Reset Password</h2>
                                  
                                </div>
                                <div class="card-padding pb-4">
                              
                                  <v-text-field
                                    label="Email"
                                    color="#401478"
                                    outlined
                                    rounded
                                    ref="email"
                                    v-model="email"
                                    prepend-inner-icon="fa-envelope"
                                    dense
                                    class="font-size-input input-style py-0 input-login-new"
                                  ></v-text-field>                                 
                                  <v-btn
                                    elevation="0"
                                    :ripple="false"
                                    height="43"
                                    rounded
                                    :loading="buttonFlag"
                                    class="
                                      font-weight-bold
                                      text-uppercase
                                      btn-primary
                                      
                                      py-2
                                      px-6
                                      me-2
                                      mt-7
                                      mb-2
                                      w-100
                                    "
                                    color="#401478"
                                    small
                                    style="margin-top: -40px;"
                                    v-on:click="forgotPass"
                                    >Submit</v-btn
                                  >
                                  <v-btn
                                    elevation="0"
                                    :ripple="false"
                                    height="43"
                                    rounded
                                    class="
                                      font-weight-bold
                                      text-uppercase
                                      btn-primary
                                      
                                      py-2
                                      px-6
                                      me-2
                                      mt-7
                                      mb-2
                                      w-100
                                    "
                                    color="grey"
                                    small
                                    style="margin-top: -40px;"
                                    v-on:click="forgot_flag = false"
                                    >Back to login</v-btn
                                  >
                                 
                                </div>
                        </v-card>
                        <div class="card-padding pb-0 new-login-left-form" style="text-align:center;border: none;" >
                                  
                          <p class="mb-0 text-label ">
                                    If you don't have an account. Get start from here
                                  </p>
                                  <v-btn
                                    elevation="0"
                                    :ripple="false"
                                    height="43"
                                    rounded
                                    class="
                                      font-weight-bold
                                      text-uppercase
                                      btn-primary
                                      
                                      py-2
                                      px-6
                                      me-2
                                      mt-7
                                      mb-2
                                      w-100
                                    "
                                    color="#2E639F"
                                    small
                                    style="margin-top: -40px;"
                                    @click="$router.push('/signup-company')"
                                    >Join US Now!</v-btn
                                  >
                                </div>
                      <!-- </v-col> -->
                    </v-row>
                    </v-container>
        </v-col>
        <v-col cols="12" md="6" lg="6" style="background-color: transparent;
background-image: linear-gradient(180deg, #1D4BB6 0%, #401478 100%);padding-bottom: 0px!important;">
          <v-container class="pb-0 position-relative new-login-left" style=" justify-content: space-around;position: relative;" >
                
                <v-row class=" new-login-left-box" style="" align="center" justify="center">
                <div style="text-align: left;">
                  <!-- <h3 class="score-header-2"><span style="color: #ffffff;">SLEX</span>
                  </h3> -->
                  </div>
                  
                  <div class="e-hosted-video elementor-wrapper elementor-fit-aspect-ratio elementor-open-inline mt-10">
                    <img  :height="this.$vuetify.breakpoint.xs ? 'auto' : 'auto'" style="width: 100%;" class="image-logo2" src="@/assets/img/car-bg.png">
                  </div>
                </v-row>
          </v-container>
          
        </v-col>


      </v-row>
            </v-main>


</v-app>
</template>

<script>
// import image from "./assets/profile.png"
import Utility from "@/components/common/functions.js";
export default {
props: {
    source: String,
},

data () {
return {
    email : '',
    pageFlag: 'login',
    password : '',
    showPassword : false,
    forgot_flag : false,
    dialog: false,
    showpassword : false,
    buttonFlag: false,
    backgroundLogo: localStorage.getItem('admin_bg'),
    company : '',
    base_url_img : process.env.VUE_APP_WASBI_URL,
    loadingLogo : false,

}
},
beforeCreate: function () {
    if (localStorage.getItem('token') && localStorage.getItem('is_admin') == 1) {
        this.$router.push('/admin/client-profiles')
    }
    
},
created : function (){
  this.url = window.location.origin;//this.$route.params.url ? this.$route.params.url : '';console.log(this.$route)
    if(this.url)
        this.getCompanyInfo(this.url)
    else
        this.loadingLogo = true;
},
methods: {
gotohome: function () {
    let self = this;
    self.$router.push({ name: "Home" });
},

gotosignup: function () {
    let self = this;
    self.$router.push('/signup');
},
getCompanyInfo(url){
  axios({
          method: "POST",
           
          url: "/api/get-company-info",
          data: {
            url: url
          }
        })
          .then(response => {
            this.company = response.data.company;
            this.loadingFlag = true;
            this.loadingLogo = true;
          })

},
forgotPass(){
  if (!this.email) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }else{
    this.buttonFlag =true;
    axios({
          method: "POST",
           
          url: "/api/auth/forgot-password-admin",
          data: {
            email: this.email,
            quantum : 1,
            frontend_url : this.url,
            company : this.company
          }
        })
          .then(response => {
                this.sendFlag = 1;
                Vue.$toast.success('Forgot Password Mail sent', {
                position: 'top-right'
                });
                this.buttonFlag =false;
                this.pageFlag = 'login';
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          })
        }
},
/**
 * checkForm
 * login the user with valid email/pass
 */
checkForm: function(e) {
      var loginData = {};
      var header = {};
      let flag = 1;
      if (!this.email) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }
      if (!this.password && flag == 1) {
        Vue.$toast.error('Password is required', {
            position: 'top-right'
        });
        this.$refs['password'].focus()
        flag = 0;
      }

      if (flag == 1) {
        var email = this.email;
        axios({
          method: "POST",
           
          url: "/api/auth/login-admin",
          data: {
            email: this.email,
            password: this.password,
          }
        })
          .then(response => {
            let data = response.data;
            if (data.flag == 1) {
              let result = data.data;
              localStorage.setItem("token", data.token.token);
              localStorage.setItem("user_id", result.user_id);
              localStorage.setItem("first_name", result.first_name);
              localStorage.setItem("email", result.email);
              localStorage.setItem("phone", result.phone_m);
              localStorage.setItem("is_admin", result.is_admin);
              localStorage.setItem("name", result.first_name+' '+result.last_name);
              localStorage.setItem("role", JSON.stringify(result.roles[0]));
              localStorage.setItem("company_name", result.company_name);
              localStorage.setItem('company_logo',process.env.VUE_APP_WASBI_URL+result.logo);
              localStorage.setItem("company_id", result.company_id);
              if(result.roles[0].name !='super_admin')
                localStorage.setItem("agreement_flag", result.agreement);
              if(result.roles[0].name =='super_admin')
                this.$router.push("/admin/client-profiles");
              else{
              if(result.agreement == 0)
                this.$router.push("/admin/agreement");
              else
                this.$router.push("/admin/client-profiles");
              }
              location.reload();
            } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
      } else {

      }
    },
    checkForm2: function(e) {
      var loginData = {};
      var header = {};
      let flag = 1;
      if (!this.email) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }
      if (!this.password && flag == 1) {
        Vue.$toast.error('Password is required', {
            position: 'top-right'
        });
        this.$refs['password'].focus()
        flag = 0;
      }

      if (flag == 1) {
        var email = this.email;
        axios({
          method: "POST",
           
          url: "/api/auth/check-email-exist",
          data: {
            email: this.email,
            password: this.password,
          }
        })
          .then(response => {
            let data = response.data;
            if (data.flag == 1) {
              localStorage.setItem('logged_in_email',this.email);
              localStorage.setItem('logged_in_password',btoa(this.password));
              this.$router.push("/choose-profile");
            } else if (data.flag == 2)  {
                this.checkForm();
            } else{
              Vue.$toast.error(response.data.message, {
            position: 'top-right'
            });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
      } else {

      }
    },
  
},

}
</script>

<style scoped>
   html {
  overflow: hidden !important;
}

</style>
<style>
.separator {
    display: flex;
    align-items: center;
    text-align: center;
}
.separator::before, .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid rgba(0,0,0,.6);
}
.separator::before {
    margin-right: .25em;
}
.separator::after {
    margin-left: .25em;
}
</style>
<style>
.input-login .v-icon{
    color: #FDE038!important;
}
.input-login input{
    color: #FDE038!important;
}
.input-login .v-label{
  color: #FDE038!important;
}
.input-login #input-6{
  color: #FDE038!important;
}
.input-login #pass{
  color: #FDE038!important;
}
.input-login #input-19{
  color: #FDE038!important;
}
</style>