<template>
    <v-footer
      absolute
      class="font-weight-medium"
    >
      <v-col
        class="text-center"
        cols="12"
      >
        Copyright {{ new Date().getFullYear() }} - Slex - All Rights Reserved
      </v-col>
    </v-footer>
</template>

<script>
export default {
    
}
</script>

<style>

</style>
