<template>
<v-app id="inspire">
  <v-main style="padding:50px!important;">
    <v-container style="margin-bottom: 72px;" fluid class="px-6 py-6">
      <v-card class="card-shadow border-radius-xl mt-6" id="basic">
        <v-card-text style="margin-top:30px;">
          

          <img  :height="this.$vuetify.breakpoint.xs ? 150 : 100" class="image-logo2 mt-3" src="@/assets/img/QUANTUM FRSOLUTIONS TP old.png" style="height: 100%;
width: 300px;"> 
          <div v-html="renderHtml(letter)"></div>
          <div>
              I, {{user.first_name}} {{user.last_name}} am giving my legal consent for my Electronic Signature to be used as proof that I did in fact sign this Electronic Document. I agree to all terms set forth herein.
              </div>

          <div class="buttons-sig" >Signature</div>
          <div class="buttons-sig" >
            
          <VueSignaturePad
          id="signature"
          scaleToDevicePixelRatio
          width="500px"
          v-if="loadSignature"
          height="300px"
          ref="signaturePad"
          :options="options"
        />
          </div>
        <div class="buttons-sig" >
        <button @click="undo">Undo</button>
      </div>

        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
                class="mb-5"
                color="blue"
                dark
                @click="submitContract()"
              >Submit</v-btn>
              <v-btn
                class="mb-5"
                color="red"
                dark
                @click="logout()"
              >Logout</v-btn>
           
            </v-card-actions>
    </v-card>

    </v-container>

    <vue-footer-admin></vue-footer-admin>
     </v-main>
</v-app>

</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import AvatarCropper from 'vue-avatar-cropper'
export default {
components: {
      VueTelInput,AvatarCropper
  },
  data () {

      return {
        fname : '',
        lname : '',
        email : '',
        checkbox: false,
        name:'',
        phone:'',
        password : '',
        is_admin:0,
        preview : '',
        confpassword : '',
        role : '',
        user: [],
        options: {
      penColor: "#00f",
    },
        companyname : '',
        cropperOutputMime : '',
        changeTrigger : false,
        sector : '',
        isDark: true,
        isDarkUpload :false,
        loading:false,
        isDisable :false,
        no_of_employee : '',
        showPassword : false,
        file_flag : {
          agreement : {
                          name : '',
                          id :''
                        },
          id :{
                          name : '',
                          id :''
                        },
          bill :{
                          name : '',
                          id :''
                        },
          letter_a : {
                          name : '',
                          id :''
                        },
          letter_b : {
                          name : '',
                          id :''
                        },
        },
        phoneFlag:0,
        emailFlag:0,
        files : {
          agreement : [],
          id : [],
          bill:[],
          letter_a : [],
          letter_b : []
        },
        country :'',
        otp_email:'',
        settings : '',
        letter :'',
        otp_phone:'',
        loadSignature : false,
        company : {
          name: "",
          contact_email: "",
          contact_phone: "",
          details:"",
          email: "",
          password: "",
          password_confirmation: ""

        },
        otp_phone_input:"",
        otp_email_input:"",
        user_phone:'',
        user_email:'',
        status:0,
        html_agreement : "",
        tab: null,
        headers: [

          { text: '', value: 'CompanyURL' },
          { text: '', value: 'CompanyName' },

        ],
        history: [

        ],

        setting: [
        ],

        items: [
              'Admin',
              'Viewer',
      ],
      select_role : 'Admin',
       sectors: [

      ],
      employeeNumber: [
              '10-50',
              '50-100',
              'More than 100',

      ],

      }
    },

    methods: {
    addPhone(){
        this.phoneFlag = 1;
    },
    disableinput($event){
       $event.preventDefault();
    },
    downloadFile(key){
      let self = this;
            this.isDisableDownload = true;
            this.isDarkDownload = false;
                axios({
                    method: "POST",
                    url: "/api/download-file",
                    responseType: 'blob',
                    headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
                    data: {key : key}
                    })
                    .then(response => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                   var fileLink = document.createElement('a');

                   fileLink.href = fileURL;
                   fileLink.setAttribute('download', response.headers['file-name']);
                   document.body.appendChild(fileLink);

                   fileLink.click();
                    
                    self.isDisableDownload = false;
                    self.isDarkDownload = true;
                    })
                    .catch(err => {
                        Vue.$toast.error(err, {
                        position: 'top-right'
                        });
                    });
    },
    dataURLtoFile(dataurl, filename) {
 
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
    },

     handleUploaded(cropper) {
      let self = this;
          let image = cropper.getCroppedCanvas().toDataURL(this.cropperOutputMime);
          let img = this.dataURLtoFile(image,'asdasd.jpg');
          let preview_url =process.env.VUE_APP_WASBI_URL;
          const data = new FormData();
        data.append('file', img);
        data.append('client_id', this.user.user_id);
        this.isDarkUpload = true;
        axios({
          method: "POST",
          url: "/api/upload-avatar",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: data
          })
          .then(response => {
          Vue.$toast.success("Profile Picture uploaded", {
              position: 'top-right'
              });
              self.preview = preview_url+response.data.data;
              self.isDarkUpload = false;

          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              this.isDisableUpload = false;
              this.isDarkUpload = false;
          });

        },
        renderHtml(html){
      if(html){
        var today = new Date();
        let self = this;
        let ip = {};
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = mm + '/' + dd + '/' + yyyy;
        html = html.replaceAll("{client_suffix}",this.user.suffix);
        html = html.replaceAll("{client_first_name}",this.user.first_name);
        html = html.replaceAll("{client_middle_name}",(this.user.middle_name ? this.user.middle_name : ''));
        html = html.replaceAll("{client_last_name}",this.user.last_name);
        html = html.replaceAll("{client_address}",this.user.address_1+'<br>'+this.user.city+', '+this.user.state+' '+this.user.zip);
        html = html.replaceAll("{client_previous_address}",this.user.address_1+'<br>'+this.user.city+', '+this.user.state+' '+this.user.zip);
        html = html.replaceAll("{bdate}",this.user.dob);
        html = html.replaceAll("{ss_number}",this.user.ssn);
        html = html.replaceAll("{t_no}",this.user.phone_m);
        html = html.replaceAll("{curr_date}",today);
        // html = html.replaceAll("{client_signature}",'');
        html = html.replaceAll("{client_business_name}",this.companyname)
        html = html.replaceAll("{company_logo}",'')
        var config = {
          
          method: 'get',
          url: '/api/get-ip',
          headers: { }
        };
        axios(config)
        .then(function (response) {
           response.data;
           self.letter = self.letter.replaceAll("{client_sign_operation system}",response.data.os)
           self.letter = self.letter.replaceAll("{client_ip}",response.data.ip)
        })
      }

      return html;
    },
    getIP() {
      var config = {
  method: 'get',
  url: '/api/get-ip',
  headers: { }
};

axios(config)
.then(function (response) {
  return response.data;
})
      // $.getJSON("https://jsonip.com?callback=?", function(data) {
        
      // })
    },
    uploadFile(id){
      let self = this;
      let flag = 1;
      if(id =='agreement' && self.files.agreement.length == 0){
          Vue.$toast.error('Choose file to upload', {
          position: 'top-right'
          });
          flag = 0;
      }
      else if(id =='id' && self.files.id.length == 0){
         Vue.$toast.error('Choose file to upload', {
          position: 'top-right'
          });
          flag = 0;
      }else if(id =='bill' && self.files.bill.length == 0){
          Vue.$toast.error('Choose file to upload', {
          position: 'top-right'
          });
          flag = 0;
      }else if(id =='letter_a' && self.files.letter_a.length == 0){
         Vue.$toast.error('Choose file to upload', {
          position: 'top-right'
          });
          flag = 0;
      }else if(id =='letter_b' && self.files.letter_b.length == 0){
          Vue.$toast.error('Choose file to upload', {
          position: 'top-right'
          });
          flag = 0;
      }
      else{
          const data = new FormData();
          if(id == 'agreement')
            data.append('file_agreement', self.files.agreement);
          if(id == 'id')
            data.append('file_id', self.files.id);
          if(id == 'bill')
            data.append('file_bill', self.files.bill);
          if(id == 'letter_a')
            data.append('file_letter_a', self.files.letter_a);
          if(id == 'letter_b')
            data.append('file_letter_b',self.files.letter_b);
          data.append('flag',id);
          axios({
          method: "POST",
          url: "/api/upload-file",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: data
          })
          .then(response => {
          console.log(response);
          Vue.$toast.success("File uploaded", {
              position: 'top-right'
              });
          self.getUserProfile();
          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              this.isDisableUpload = false;
              this.isDarkUpload = true;
          });
      }
    },
    logout() {
        let headers = {
            Authorization: "Bearer " + localStorage.getItem('token')
        }
      axios
        .get("/api/logout", {headers: headers})
        .then(response => {
            localStorage.removeItem("token")
            localStorage.removeItem("user_id");
            localStorage.removeItem("email");
            localStorage.removeItem("name");
            localStorage.removeItem("is_admin");
            this.$router.push("/admin");
            window.location.reload();
        })
        .catch(error => {
            localStorage.removeItem("token")
            localStorage.removeItem("user_id");
            localStorage.removeItem("email");
            localStorage.removeItem("name");
            this.$router.push("/admin");
            location.reload();
        });
    },
    submitContract(){
        let data = this.$refs.signaturePad.saveSignature();
        if(!data.data){
          Vue.$toast.error('Please sign to submit the agreement', {
                    position: 'top-right'
                    });
        }else{
          axios({
          method: "POST",
          url: "/api/submit-user-agreement/"+localStorage.getItem('company_id'),
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: {
            data : data,
            template : this.renderHtml(this.letter)
          }
          })
          .then(response => { 
            if(response.data.flag == 1){
                    localStorage.setItem('agreement_flag',1)
                    this.$router.push('admin/dashboard')
                  }

                })
                
        }
      },
    loadSig(){
      let self = this
      setTimeout(function(){
        self.loadSignature = true
      },1000)
      
    },
    getSettings() {
    let flag = 1;
    let url =process.env.VUE_APP_WASBI_URL
    var config = {headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache'}};
      axios({
          method: "POST",
           
          url: "/api/get-company-settings",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
              this.id = data.id;
              this.settings = data.settings;
              this.letter = this.settings.agreement
            }


         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },
    deleteChip(index){
      if(index == 'agreement')
        this.file_flag.agreement.name = ''
      if(index == 'id')
        this.file_flag.id.name = '';
      if(index == 'bill')
        this.file_flag.bill.name = '';
      if(index == 'leter_a')
        this.file_flag.letter_a.name = '';
      if(index == 'letter_b')
        this.file_flag.letter_b.name = '';
    },
    addEmail(){
        this.emailFlag = 1;
    },
    countryChanged(country) {
        this.country = '+' + country.dialCode
    },
      changePassword: function () {
          let flag = 1;
          if (!this.password) {
            Vue.$toast.error('Password is required', {
                position: 'top-right'
            });
            flag = 0;
          }

          if (!this.confpassword) {
            Vue.$toast.error('Confirm Password is required', {
                position: 'top-right'
            });
            flag = 0;
          }
          if (this.confpassword != this.password && flag == 1) {
            Vue.$toast.error('Confirm password did not match', {
                position: 'top-right'
            });
            self.$refs['conf_password'].focus()
            flag = 0;
        }

          if (flag == 1) {

            axios({
            method: "POST",
             
            url: "/api/passwordchange",
            data: {
            password: this.password,
            password_confirmation: this.confpassword,
          }
        })
          .then(response => {
            let data = response.data;
            if (data.data == 1) {
              Vue.$toast.success('Password has been changed', {
                position: 'top-right'
                });
            this.password = '';
            this.confpassword = '';

            } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
      } else {

      }

    },

    add: function () {
         // for setting tab
    },

    getUserProfile() {
    let flag = 1;
    let self = this;
    let avatar_url = process.env.VUE_APP_WASBI_URL
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/user",header)
        .then(response => {
            let data = response.data;

           if (flag == 1) {
              this.user = data.user;
              this.companyname = data.company.name
              this.preview = data.user.avatar ? avatar_url+data.user.avatar : ''
            }else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },



     undo() {
      this.$refs.signaturePad.undoSignature();
    },
    saveProfile: function(e) {
      let self = this;
      let flag = 1;
      let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        if (!this.user.first_name) {
            Vue.$toast.error('First Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.user.last_name && flag == 1) {
            Vue.$toast.error('Last Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.user.email && flag == 1) {
            Vue.$toast.error('Email is required', {
                position: 'top-right'
            });
            this.$refs['email'].focus()
            flag = 0;
        }
        
        let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (reg.test(this.user.email) == false && flag == 1 ) {
            Vue.$toast.error('Invalid Email format', {
            position: 'top-right'
            });
            this.$refs['email'].focus()
            flag = 0;
        }

        if (flag == 1) {
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/updateprofile",
          data: self.user
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
                  Vue.$toast.success('Profile updated', {
                    position: 'top-right'
                  })
                  //this.$router.push('company-profiles')

            } else {
                Vue.$toast.error('Failed To Add', {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            console.log(err.response);
            
              if(err.response.data.errors.email)
                Vue.$toast.error(err.response.data.errors.email[0], {
                position: 'top-right'
                });
              if(err.response.data.errors.user_name)
                Vue.$toast.error(err.response.data.errors.user_name[0], {
                position: 'top-right'
                });            
          });
        }
    },

 },

created() {
      console.log('created called.');
      this.getSettings();
      this.getUserProfile();
      this.loadSig()
    },

}
</script>

