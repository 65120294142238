<template>
    <v-footer
      bottom
      style="background:#171717;"
      class="font-weight-medium"
    >
      <v-col
        class="text-center text-white"
        cols="12" md="8" lg="8"
        
      >
        <span style="color: #666;">@ {{ new Date().getFullYear() }} - ScoreZoom - All Rights Reserved </span>
      </v-col>
      <v-col
        class="text-center text-white"
        cols="12" md="4" lg="4"
      ><v-icon class="mr-3" size="25" style="color: #666;" @click="gotoLink('https://www.facebook.com/scorezoom')" >
        fab fa-facebook
          </v-icon><v-icon class=" mr-3" size="25" style="color: #666;" @click="gotoLink('https://twitter.com/scorezoom')">
            fab fa-twitter
          </v-icon><v-icon class="me-sm-1" size="25" style="color: #666;" @click="gotoLink('https://www.instagram.com/scorezoom/')">
            fab fa-instagram
          </v-icon></v-col>
    </v-footer>
</template>

<script>
export default {
    methods : {
      gotoLink(item){
          window.open(item, "_blank");
        },
    }
}
</script>

<style>

</style>
